import config from "../config";

export function loadSpreadsheet(callback: Function, sheetName: string) {
    window.gapi.load("client", () => {
        window.gapi.client
            .init({
                apiKey: config.apiKey,
                // Your API key will be automatically added to the Discovery Document URLs.
                discoveryDocs: config.discoveryDocs
            })
            .then(() => {
                // 3. Initialize and make the API request.
                internalLoad(callback, sheetName);
            });
    });
}

function internalLoad(callback: Function, sheetName: string) {
    window.gapi.client.load("sheets", "v4", () => {
        (window.gapi.client as any).sheets.spreadsheets.values
            .get({
                spreadsheetId: config.spreadsheetId,
                range: `${sheetName}!A1:N`
            })
            .then((response: any) => {
                callback(mapSheetData(response.result.values));
            },
                (response: any) => {
                    callback(false, response.result.error);
                }
            );
    });
}

function mapSheetData(data: any[]) {
    let header;
    const adaptedData: any[] = [];

    for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (i === 0) {
            header = element;
            continue;
        }

        const adaptedElement = {} as any;

        for (let y = 0; y < element.length; y++) {
            const elemItself = element[y];

            adaptedElement[header[y]] = elemItself;
        }

        adaptedData.push(adaptedElement);
    }

    return adaptedData;
}