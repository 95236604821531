import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from './pages/Home/Home';

function App() {
  return (
    <div className="ui container">
      <Router>
        <Route path="/" exact component={HomePage} />
      </Router>
    </div>
  );
}

export default App;
