import React, { useRef, useState, useEffect } from 'react';
import RoCounties from "./../../ro-counties";
import { loadSpreadsheet } from '../../util/spreadsheet';
import Preloaded from '../../components/Preloaded/Preloaded';
import 'nprogress/nprogress.css';
import nprogress from "nprogress";

export class CaseData {
    public caseNr!: number;
    public countyCode: string = "";
    public countyDispName: string = "";
    public isUnknown: boolean = true;

    constructor(inputData: any) {
        if (inputData["Nr. Crt."]) {
            const parsed = parseInt((inputData["Nr. Crt."] || "").trim(), 10);

            if (!isNaN(parsed)) {
                this.caseNr = parsed;
            }
        }

        const cty = (inputData["Judet"] || "").trim();

        if (cty) {
            this.countyCode = cty;
        } else {
            this.countyCode = "NA";
        }

        const dispName = (RoCounties as any)[this.countyCode];

        if (dispName) {
            this.countyDispName = dispName;
            this.isUnknown = false;
        } else {
            this.countyDispName = "???";
            this.isUnknown = true;
        }
    }

    public getRenderValue() {
        return `${this.countyDispName} (${this.countyCode})`;
    }
}

export class CountyCounterInfo {
    constructor(public nr: number, public name: string, public total: number) {

    }
}

const HomePage: React.FC = () => {
    const timeoutId = useRef(0);
    const isFetching = useRef(false);
    const [renderData, setRenderData] = useState<CountyCounterInfo[]>([]);

    function mapData(result: any[]) {
        nprogress.done();
        isFetching.current = false;

        const caseDict: { [key: string]: CaseData[] } = {};
        Object.keys(RoCounties).forEach(x => caseDict[x] = []);

        if (result && result.length) {
            for (let i = 0; i < result.length; i++) {
                const caseRow = new CaseData(result[i]);
                const keyDefined = !!caseDict[caseRow.countyCode];

                if (!keyDefined) {
                    caseDict[caseRow.countyCode] = [];
                }

                caseDict[caseRow.countyCode].push(caseRow);
            }

            const finalData = Object.keys(caseDict).map((x, i) => {
                const currentCases = caseDict[x];
                const total = currentCases.length;
                let name = x;

                if (total > 0) {
                    name = `${name} (${currentCases[0].countyDispName})`;
                }

                return new CountyCounterInfo(i + 1, name, total);
            });

            setRenderData(finalData);
        }
    }

    useEffect(() => {
        clearTimeout(timeoutId.current);
        timeoutId.current = window.setInterval(() => {
            if (isFetching.current) {
                return;
            }

            isFetching.current = true;
            nprogress.start();
            loadSpreadsheet(mapData, "Cazuri");
        }, 3000);
    }, []);


    function getKey(item: any, index?: number): string {
        return item.key;
    }

    return (
        <div>
            <div className="Text-center">
                <h1 className="ui huge header AppHeader" style={{ margin: "16px 0px" }}>
                    Cazuri / judete
                </h1>
            </div>

            <Preloaded isLoading={!renderData.length}>
                <table className="ui celled table" style={{marginBottom: 20}}>
                    <thead>
                        <tr>
                            <th style={{ width: 100 }}>Nr. Crt.</th>
                            <th>Judet</th>
                            <th style={{ width: 100 }}>Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {renderData.map((row, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <span>
                                            {row.nr}
                                        </span>
                                    </td>

                                    <td>
                                        {row.name}
                                    </td>

                                    <td>
                                        <strong>
                                            {row.total}
                                        </strong>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Preloaded>
        </div>
    );
}

export default HomePage;
